import axios from "axios"

let vlogin_id = localStorage.getItem('user_login_id');
class MpTransactionService {


    list_recent() {
        //axios.headers.common['Token-Auth'] = localStorage.getItem('mtoken_auth');
        //console.log('axios',axios);
        let form = {user_login_id:vlogin_id};
        
        return axios.post("mp_transaction/list_recent", form);

    }

    view_transaction(form) {
        form['user_login_id'] = vlogin_id;
        return axios.post("mp_transaction/view_transaction", form);

    }  
    
    search_transaction(form) {
        form['user_login_id'] = vlogin_id;
        return axios.post("mp_transaction/search_transaction", form);

    }
    
}
    
 

export default new MpTransactionService();